<template>
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 0L16 14H0L8 0Z" fill="currentColor" />
  </svg>
</template>
